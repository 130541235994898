
import { defineComponent } from "vue";
import { mapState, mapGetters } from "vuex";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import TieredMenu from "primevue/tieredmenu";
import Tag from "primevue/tag";
import store from "@/store";
import Settings from "@/components/Settings.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TopStatCards from './components/StatCards/TopStatCards.vue';

const SMALL_SCREEN_BREAKPOINT = 575;

export default defineComponent({
  name: "App",
  components: {
    Button,
    Toolbar,
    TieredMenu,
    Sidebar,
    Settings,
    TabView,
    TabPanel,
    TopStatCards,
    Tag,
  },
  beforeCreate() {
    store.dispatch("theme/updateTheme");

    document.title = store.getters["theme/getTitle"] + " " + "Customer Portal";
  },
  created() {
    if (screen.width < 480) {
      store.dispatch("sidemenu/setCollapsed");
    }
  },
  mounted() {
    const getResetTimer = this.resetTimer;
    this.events.forEach((event) => {
      window.addEventListener(event, getResetTimer);
    }, this);
    this.setTimers();
    window.addEventListener("resize", this.resize);

    //this.populateTabItems();
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);

    const getResetTimer = this.resetTimer;
    this.events.forEach((event) => {
      window.removeEventListener(event, getResetTimer);
    }, this);
    this.resetTimer();
    this.$.appContext.app.config.globalProperties.$posthog.reset();
  },
  computed: {
    ...mapState(["session", "customer", "theme", "env", "control"]),
    ...mapGetters({
      getClient: "session/getClient",
    }),
    userInitials(): string {
      const splitName = this.customer.customer?.name.split(' ');
      return splitName[0].charAt(0) + splitName[0].charAt(1);
    },
    isDataLoaded(): boolean {
      return store.getters["session/getdataLoaded"];
    },
    printableView(): boolean {
      return this.$route.path.includes("printable-view");
    },
    headerTitle(): string {
      return `${this.control.company.name ? this.control.company.name : this.session.client} ${!this.isSmallScreen ? "Portal" : ""}`;
    },
    hideCompanyNameOnDropdown() : boolean {
      return this.isSmallScreen;
    },
    isSmallScreen(): boolean {
      return this.windowWidth < SMALL_SCREEN_BREAKPOINT;
    },
  },

  watch: {
    getClient: {
      async handler() {
        if (this.getClient) {
          const promises = [
            store.dispatch("fdict/fetchFdict", "SO"),
          ];
          try {
            await Promise.allSettled(promises);
          } catch (err) {
            // noop
          }
        }
      },
      immediate: true,
    },
    isDataLoaded(newVal) {
      // Check if the new value of isDataLoaded is true
      if (newVal) {
        this.populateTabItems();
      }
    }
  },

  methods: {
    logout() {
      this.$.appContext.app.config.globalProperties.$posthog.reset(); // this is a hack and it is terrible, but it works. Goal #1 for right now is to get a successful build.
      store.dispatch("session/logout");
    },
    toggleProfileMenu(event: any) {
      (this.$refs.menu as any).toggle(event);
    },
    settings() {
      this.displaySettings = true;
    },
    setTimers() {
      this.LogoutTimer = setTimeout(this.logout, 30 * 60 * 1000);
    },
    resetTimer() {
      clearTimeout(this.LogoutTimer);
      this.setTimers();
    },
    populateTabItems(){
      if(this.env.modules.includes('Sales') || this.env.modules.length == 0){
        this.items.push({ label: "Sales ", icon: "pi pi-fw pi-dollar", to: "/sales" });
      }
      if(this.env.modules.includes('Tickets') || this.env.modules.length == 0){
        this.items.push( { label: "Tickets ", icon: "pi pi-fw pi-money-bill", to: "/tickets" },);
      }
      if(this.env.modules.includes('Invoices') || this.env.modules.length == 0){
        this.items.push( { label: "Invoices", icon: "pi pi-fw pi-folder", to: "/invoices",},);
      }
    },
    resize() {
      this.windowWidth = window.innerWidth;
    }
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      LogoutTimer: 0,
      menuitems: [
        {
          label: "Log Out",
          icon: "pi pi-calendar",
          service: this.logout,
        },
        {
          label: "Settings",
          icon: "pi pi-cog",
          service: this.settings,
        },
      ],
      showMenu: false,
      displaySettings: false,
      items: [] as any,      
      windowWidth: window.innerWidth,
    };
  },
});
